section.game-templates-page {
    background-color: white !important;
    color: black;
    max-width: 100vw !important;
    width: 78vw;
    margin: 20px auto;
    font-size: 1rem;
    border-radius: 5px !important;
}

section.game-templates-page * {
    color: black;
}

.game-templates-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.game-template-form {
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
}
.game-template-form form {
    border: 0;
    box-shadow: none;
}

.game-template-form * {
    color: black;
}

.game-templates-page__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid lightgray;
    margin-bottom: 20px;
}

.game-templates-page__title {
    margin: 0;
    text-align: right;
    font-size: 1.5rem;
}

button.game-templates-page__back-btn {
    padding: 0;
    background-color: transparent;
    color: gray;
    border: 0;
    padding: 5px 15px;
    margin: 0;
    font-size: 2rem;
}

.game-templates-page__back-btn svg {
    color: gray;
    fill: gray;
}
.calendar-event-games span.calendar-event-game__time {
    margin-top: 0;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1rem;
}

span.calendar-event-game__template {
    font-size: 0.8rem;
    font-weight: 100;
}
