.host-sales-page {
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 0;
    background-color: #f4f4f9;
    color: #333;
}

.host-sales-page-header {
    padding: 40px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 90%;
    margin: 0 auto;
}

.host-sales-page-header h1 {
    margin: 0 0 20px 0;
    font-size: 3rem;
    line-height: 3rem;
    text-align: left;
}

.price {
    font-size: 2rem;
    text-align: left;
    line-height: 2rem;
}

.original-price {
    text-decoration: line-through;
    margin-right: 10px;
    color: #dc3545;
}

.signup-button {
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1.2em;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.signup-button:hover {
    background-color: #218838;
}

.host-sales-features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 40px 0;
}

.feature {
    background-color: #ffffff;
    border: 1px solid #e9ecef;
    border-radius: 10px;
    padding: 30px;
    margin: 15px;
    width: 100%;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
}

.feature:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feature .icon {
    font-size: 3em;
    color: #007bff;
    margin-bottom: 20px;
}

.feature h3 {
    font-size: 2rem;
    margin: 10px 0;
    line-height: 1.7rem;
}

.host-sales-page-footer {
    color: white;
    padding: 40px 20px;
}
.discountTitle {
    font-size: 1.5em;
    margin: 20px 0;
}
span.discountTitle {
    display: block;
    font-size: 1.5rem;
    color: green !important;
    font-weight: 700;
}
h2.host-sales-page-never-again {
    margin-top: 5vh;
}
ul.host-sales-page-list-never {
    margin: 0;
    list-style: none;
    padding: 0;
    font-size: 1.5rem;
}
ul.host-sales-page-list-never li {
    color: red !important;
}
span.discounted-price {
    font-weight: 700;
    color: green !important;
}
.discounted-price small {
    color: green !important;
    font-size: 1.25rem;
    position: relative;
    top: -10px;
}
.image {
    flex: 1;
    min-width: 50%;
}
.feature .image img {
    width: 100%;
}
.feature {
    display: flex;
    flex-direction: row;
}

.feature-info {
    padding: 0 2vw;
}

.feature-info p {
    font-size: 1.5rem;
    line-height: 2rem;
}

button.game-credits-button {
    display: flex;
    flex-direction: column;
}

span.game-credits-icon {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

span.game-credits-icon * {
    font-size: 1.25rem;
}

span.game-credits-amount {
    font-size: 2rem;
    line-height: 2.5rem;
    position: relative;
    top: 2px;
}

span.game-credits-label {
    width: 100%;
    text-align: center;
    display: block;
}

.purchase-options {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-around;
}

.purchase-options button {
    padding: 10px;
    font-size: 1.2rem;
    flex-basis: 25%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    border-width: 3px;
    border: 0;
}
.purchase-options span.discounted-price {
    color: black !important;
    font-weight: 700;
    font-size: 1.5rem;
}
span.game-credit-amount {
    font-size: 1rem;
    font-weight: 700;
}

.purchase-options button:hover {
    background-color: var(--color-link);
}

.host-sales-page-header-left {
    flex: 1;
    text-align: left;
}

.host-sales-page-header-right {
    flex: 1;
}
.become-a-host-now-button,
.request-demo-button {
    padding: 0 20px 13px;
    background: green;
    border: 0;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: 20px 10px 20px 0;
}

.request-demo-button {
    background: #e3bb0a;
}

.become-a-host-now-button svg,
.request-demo-button svg {
    position: relative;
    fill: white;
    font-size: 2rem;
    line-height: 2rem;
    top: 10px;
    margin-right: 10px;
}
.request-demo-button svg {
    fill: black;
}

.become-a-host-now-button span,
.request-demo-button span {
    color: white !important;
}
.request-demo-button span {
    color: black !important;
}

video.sales-video {
    width: 40vw;
    border: 1px solid black;
    border-left-width: 3px;
    border-bottom-width: 3px;
}

main.main-content.dark.false.main-host {
    padding: 70px 0 0 0 !important;
}
.host-sales-page-testimonials {
    background: black;
    padding: 100px 0;
    position: relative;
}

.host-sales-page-testimonial {
    width: 50%;
    margin: 0 auto;
}

p.testimonial-text {
    color: white !important;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 0 40px 0;
}

p.host-sales-page-testimonial-author {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

span.testimonial-author-image img {
    width: 80px;
    border-radius: 50%;
}

span.testimonial-author-name {
    color: white !important;
    font-size: 1.5rem;
    margin-left: 20px;
}
h2 {
}

.features h2 {
    text-align: left;
    width: 80%;
    margin: 0 auto;
    font-size: 2.5rem;
}

.features {
    padding: 100px 0;
}

ul.features-list {
    list-style: none;
    display: flex;
    padding-left: 0;
    width: 80%;
    margin: 0 auto;
}

li.feature-item {
    text-align: left;
    flex: 1;
    flex-basis: 30%;
    box-sizing: border-box;
    margin-right: 3%;
}

h3.feature-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0;
}

p.feature-description {
    font-size: 1.2rem;
    line-height: 1.2rem;
}
.host-sales-page img {
    max-width: 100%;
}
.purchase-options button svg {
    border: 5px solid gold;
    display: block;
    font-size: 3rem;
    padding: 25px;
    box-sizing: border-box;
    width: auto;
    height: auto;
    fill: gold;
    background: black;
    border-radius: 5px;
}

.purchase-options button svg:hover {
    background-color: gold;
    fill: black;
}

.purchase-options button:hover {
    background: transparent;
    filter: unset;
}

span.game-credit-amount {
    font-size: 1.5rem;
    position: relative;
    top: -18px;
    background: black;
    padding: 2px 10px 0;
    box-sizing: border-box;
    color: white !important;
    border: 2px solid gold;
    border-radius: 13px;
}
.purchase-options button svg.current {
    background-color: gold;
    fill: black;
}
.testimonial-arrow {
    fill: rgb(155 155 254);
    position: absolute;
    top: 44%;
    left: 25px;
    font-size: 3rem;
    cursor: pointer;
}

.testimonial-arrow-right {
    right: 25px;
    left: unset;
}

.testimonial-arrow:hover {
    filter: brightness(1.5);
}
p.call-to-action-text {
    width: 50%;
    margin: 0 auto;
    color: white !important;
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 20px;
}
